/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:home',
        link : '/home'
    },

    {
        id      : 'prospects',
        title   : 'Gestion des Prospects',
        type    : 'collapsable',
        icon    : 'heroicons_outline:user-group',
        children: [
            {
                id   : 'listProspects',
                title: 'Liste des Prospects',
                type : 'basic',
                link : '/prospects'
            },
            {
                id   : 'addProspect',
                title: 'Ajouter un Prospect',
                type : 'basic',
                link : '/create-prospect'
            },
        ]
    },
    {
        id      : 'projects',
        title   : 'Gestion des Projets',
        type    : 'collapsable',
        icon    : 'heroicons_outline:clipboard-check',
        children: [
            {
                id   : 'listProjects',
                title: 'Liste des Projets',
                type : 'basic',
                link : '/projets'
            },
            {
                id   : 'addProject',
                title: 'Ajouter un Projet',
                type : 'basic',
                link : '/create-projet'
            },
        ]
    },
    {
        id      : 'estimations',
        title   : 'Gestion des Estimations',
        type    : 'basic',
        icon    : 'heroicons_outline:archive',
        link    : '/estimations'
    },
    {
        id      : 'functionalities',
        title   : 'Gestion des Fonctionnalités',
        type    : 'collapsable',
        icon    : 'heroicons_outline:puzzle',
        children: [
            {
                id   : 'listFunctionalities',
                title: 'Liste des Fonctionnalités',
                type : 'basic',
                link : '/functionalities'
            },
            {
                id   : 'addFunctionality',
                title: 'Ajouter une Fonctionnalité',
                type : 'basic',
                link : '/create-functionality'
            },
        ]
    },
    {
        id      : 'types',
        title   : 'Gestion des Types',
        type    : 'collapsable',
        icon    : 'heroicons_outline:menu',
        children: [
            {
                id   : 'listTypes',
                title: 'Liste des Types',
                type : 'basic',
                link : '/types'
            },
            {
                id   : 'addType',
                title: 'Ajouter un Type',
                type : 'basic',
                link : '/create-type'
            },
        ]
    },
    {
        id      : 'pages',
        title   : 'Gestion des Pages',
        type    : 'collapsable',
        icon    : 'heroicons_outline:folder-open',
        children: [
            {
                id   : 'listPsges',
                title: 'Liste des Pages',
                type : 'basic',
                link : '/pages'
            },
            {
                id   : 'addPage',
                title: 'Ajouter une Page',
                type : 'basic',
                link : '/create-page'
            },
        ]
    },
    {
        id      : 'technologyCategories',
        title   : 'Gestion des Catégories Technologiques',
        type    : 'collapsable',
        icon    : 'heroicons_outline:server',
        children: [
            {
                id   : 'listTechnologyCategories',
                title: 'Liste des Catégories Technologiques',
                type : 'basic',
                link : '/technology_categories'
            },
            {
                id   : 'addTechnologyCategory',
                title: 'Ajouter une Catégorie Technologique',
                type : 'basic',
                link : '/create-technology_category'
            },
        ]
    },

    {
        id      : 'technologycomplexities',
        title   : 'Gestion des Technology Complexity',
        type    : 'collapsable',
        icon    : 'heroicons_outline:cloud',
        children: [
            {
                id   : 'technologycomplexitiesa',
                title: 'List Technology Complexity',
                type : 'basic',
                link : '/technology_complexities'
            },
            {
                id   : 'technologycategoriess',
                title: 'Ajouter Technology Complexity',
                type : 'basic',
                link : '/create-technology_complexity'
            },

        ]
    },

    {
        id      : 'apps.technology',
        title   : 'Gestion des technologies',
        type    : 'collapsable',
        icon    : 'heroicons_outline:clipboard-check',
        children: [
            {
                id   : 'listtechnologies',
                title: 'List technologies',
                type : 'basic',
                link : '/technologies'
            },
            {
                id   : 'addtechnologies',
                title: 'Ajouter technology',
                type : 'basic',
                link : '/create-technology'
            },
        ]
    },

    {
        id      : 'users',
        title   : 'Gestion des Utilisateurs',
        type    : 'collapsable',
        icon    : 'heroicons_outline:chart-pie',
        children: [
            {
                id   : 'listUsers',
                title: 'Liste des Utilisateurs',
                type : 'basic',
                link : '/users'
            },
            {
                id   : 'addUser',
                title: 'Ajouter un Utilisateur',
                type : 'basic',
                link : '/create-user'
            },
        ]
    },

    {
        id      : 'Keyvalues',
        title   : 'Gestion des Keyvalues',
        type    : 'collapsable',
        icon    : 'heroicons_outline:collection',
        children: [
            {
                id   : 'listKeyvalues',
                title: 'Liste des Keyvalues',
                type : 'basic',
                link : '/keyvalues'
            },
            {
                id   : 'addUser',
                title: 'Ajouter un Keyvalue',
                type : 'basic',
                link : '/create-keyvalue'
            },
        ]
    },
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
