import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'home'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'home'},

    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'accueil', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)},

            {path: 'users', loadChildren: () => import('app/modules/admin/users/users.module').then(m => m.UsersModule)},
            {path: 'create-user', loadChildren: () => import('app/modules/admin/users/create-user.module').then(m => m.CreateUserModule)},
            {path: 'edit-user/:id', loadChildren: () => import('app/modules/admin/users/edit-user.module').then(m => m.EditUserModule)},

            {path: 'prospects', loadChildren: () => import('app/modules/admin/prospects/prospects.module').then(m => m.ProspectsModule)},
            {path: 'create-prospect', loadChildren: () => import('app/modules/admin/prospects/create-prospect.module').then(m => m.CreateProspectModule)},
            {path: 'edit-prospect/:id', loadChildren: () => import('app/modules/admin/prospects/edit-prospect.module').then(m => m.EditProspectModule)},

            {path: 'projets', loadChildren: () => import('app/modules/admin/projets/projets.module').then(m => m.ProjetsModule)},
            {path: 'display-projet/:id', loadChildren: () => import('app/modules/admin/projets/display-projet.module').then(m => m.DisplayProjetModule)},
            {path: 'create-projet', loadChildren: () => import('app/modules/admin/projets/create-projet.module').then(m => m.CreateProjetModule)},
            {path: 'edit-projet/:id', loadChildren: () => import('app/modules/admin/projets/edit-projet.module').then(m => m.EditProjetModule)},

            {path: 'estimations', loadChildren: () => import('app/modules/admin/estimations/estimations.module').then(m => m.EstimationsModule)},

            {path: 'types', loadChildren: () => import('app/modules/admin/types/types.module').then(m => m.TypesModule)},
            {path: 'display-type/:id', loadChildren: () => import('app/modules/admin/types/display-type.module').then(m => m.DisplayTypeModule)},
            {path: 'create-type', loadChildren: () => import('app/modules/admin/types/create-type.module').then(m => m.CreateTypeModule)},
            {path: 'edit-type/:id', loadChildren: () => import('app/modules/admin/types/edit-type.module').then(m => m.EditTypeModule)},

            {path: 'functionalities', loadChildren: () => import('app/modules/admin/functionalities/functionalities.module').then(m => m.FunctionalitiesModule)},
            {path: 'create-functionality', loadChildren: () => import('app/modules/admin/functionalities/create-functionality.module').then(m => m.CreateFunctionalityModule)},
            {path: 'edit-functionality/:id', loadChildren: () => import('app/modules/admin/functionalities/edit-functionality.module').then(m => m.EditFunctionalityModule)},

            {path: 'technology_categories', loadChildren: () => import('app/modules/admin/technology_categories/technology_categories.module').then(m => m.Technology_CategoryModule)},
            {path: 'create-technology_category', loadChildren: () => import('app/modules/admin/technology_categories/create-technology_category.module').then(m => m.CreateTechnology_CategoryModule)},
            {path: 'edit-technology_category/:id', loadChildren: () => import('app/modules/admin/technology_categories/edit-technology_category.module').then(m => m.EditTechnology_CategoryModule)},

            {path: 'technology_complexities', loadChildren: () => import('app/modules/admin/technology_complexities/technology_complexities.module').then(m => m.Technology_ComplexityModule)},
            {path: 'create-technology_complexity', loadChildren: () => import('app/modules/admin/technology_complexities/create-technology_complexity.module').then(m => m.CreateTechnology_ComplexityModule)},
            {path: 'edit-technology_complexity/:id', loadChildren: () => import('app/modules/admin/technology_complexities/edit-technology_complexity.module').then(m => m.EditTechnology_ComplexityModule)},

            {path: 'technologies', loadChildren: () => import('app/modules/admin/technologies/technologies.module').then(m => m.TechnologiesModule)},
            {path: 'display-technology/:id', loadChildren: () => import('app/modules/admin/technologies/display-technology.module').then(m => m.DisplayTechnologyModule)},
            {path: 'create-technology', loadChildren: () => import('app/modules/admin/technologies/create-technology.module').then(m => m.CreateTechnologyModule)},
            {path: 'edit-technology/:id', loadChildren: () => import('app/modules/admin/technologies/edit-technology.module').then(m => m.EditTechnologyModule)},

            {path: 'pages', loadChildren: () => import('app/modules/admin/pages/pages.module').then(m => m.PagesModule)},
            {path: 'create-page', loadChildren: () => import('app/modules/admin/pages/create-page.module').then(m => m.CreatePageModule)},
            {path: 'edit-page/:id', loadChildren: () => import('app/modules/admin/pages/edit-page.module').then(m => m.EditPageModule)},

            {path: 'keyvalues', loadChildren: () => import('app/modules/admin/keyvalues/keyvalues.module').then(m => m.KeyvaluesModule)},
            {path: 'create-keyvalue', loadChildren: () => import('app/modules/admin/keyvalues/create-keyvalue.module').then(m => m.CreateKeyvalueModule)},
            {path: 'edit-keyvalue/:id', loadChildren: () => import('app/modules/admin/keyvalues/edit-keyvalue.module').then(m => m.EditKeyvalueModule)},

        ]
    }
];
